/* WhatsAppChat.css */

.whatsapp-chat {
    position: fixed;
    bottom: 10px;
    left: 10px;
    z-index: 1000;
  }
  
  .whatsapp-chat-button {
    /* background-color: #25d366; */
    /* color: #fff; */
    border: none;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
.whatsapp-chat-button img {
    width: 40px;
    height: auto;
  }
  