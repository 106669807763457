.policy-card {
  font-family: Montserrat,Verdana,Helvetica,Arial,sans-serif;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .policy-heading {
    font-family: PlayfairDisplay,Georgia,Times New Roman,serif !important;
    font-size: 34px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .policy-list {
    list-style: none;
    padding: 0;
  }
  
  .policy-point {
    font-size: 16px;
    margin-bottom: 15px;
  }
  
  .tick-icon {
    margin-right: 10px;
    font-size: 20px;
    font-weight: bold;
    color: green;
  }
  