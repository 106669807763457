.facilities-container {
    padding: 20px;
  }
  
  .facility-col {
    margin-bottom: 20px;
  }
  
  .facility-card {
    border: 1px solid #dee2e6;
    background-color: #f7f6f6;
    border-radius: 10px;
    text-align: center;
  }
  
  .facility-icon {
    font-size: 30px;
    margin: 20px 0;
    color: #00c8ff; /* Change color as needed */
  }
  
  .facility-title {
    font-family: Montserrat,Verdana,Helvetica,Arial,sans-serif;
    font-size: 14px;
    font-weight: bold;
  }
  