/* ResponsiveComponent.css */
.custom-card {
    border: none;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    height: 100%;
    transition: transform 0.3s ease-in-out; /* Add transition property for smooth scaling */
  }
  
  .custom-card:hover {
    transform: scale(1.05); /* Add scaling on hover */
  }
  
  .pink {
    background-color: #ffcccc; /* Add your custom color code */
  }
  
  .blue {
    background-color: #cce5ff; /* Add your custom color code */
  }
  
  .green {
    background-color: #ccffcc; /* Add your custom color code */
  }
  
  .purple {
    background-color: #e5ccff; /* Add your custom color code */
  }
  