.cancellation-policy-container {
    margin-top: 20px;
  }
  
  .cancellation-policy-card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .cancellation-policy-heading {
    font-family: PlayfairDisplay,Georgia,Times New Roman,serif !important;
    font-size: 34px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .cancellation-policy-point {
    font-size: 16px;
    margin-bottom: 15px;
  }
  
  .tick-icon {
    margin-right: 10px;
    font-size: 20px; /* Increased size */
    font-weight: bold; /* Added bold style */
    color: #55b7d2 !important; /* Change color as needed */
  }
  
  /* Add more styles as needed */
  