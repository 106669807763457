/* Gallery.css */
.gallery {
  padding: 50px 80px 10px 80px;
}

/* #overlay { */
/* position: fixed; */
/* Sit on top of the page content */
/* display: none; */
/* Hidden by default */
/* width: 100%; */
/* Full width (cover the whole page) */
/* height: 100%; */
/* Full height (cover the whole page) */
/* top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); */
/* Black background with opacity */
/* z-index: -2; */
/* Specify a stack order in case you're using a different order for other elements */
/* cursor: pointer; */
/* Add a pointer on hover */
/* } */

.gallery .gallery-card {
  border: 1px solid #dee2e6;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  margin: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.gallery .gallery-card img {
  width: 100%;
  height: 200px; 
  object-fit: cover; 
  border-bottom: 1px solid #dee2e6;
  transition: transform 0.3s ease-in-out;
}

.gallery .gallery-card:hover img {
  transform: scale(1.3);
  position: relative;
  z-index: 1;
}

.gallery .gallery-card .card-title {
  font-family: Montserrat,Verdana,Helvetica,Arial,sans-serif;
  text-align: center;
  margin-top: 5px; 
  padding: 0px;
}

.gallery .gallery-card .card-body{
  padding: 5px;
}

@media screen and (max-width:768px) {
  .gallery .gallery-container {
    padding: 40px 0px;
  }
}

/* .wrapper {
  max-width: 1350px;
  padding: 0px 60px;
  margin: 50px auto;
  display: flex;
  flex-wrap: wrap;
  gap: 2em;

}

.wrapper img {
  cursor: pointer;
} */

/* modal */
/* .overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: black;
  display: flex;
  align-items: center;
} */

/* .overlay img {
  display: block;
  max-width: 60%;
  max-height: 90%; */
/* width: 100%; */
/* height: 100%; */
/* width: 100%;
  height: 100vh;
  margin: 60px auto;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
} */

/* .overlay>span {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 30px;
  color: #ffffff;
  z-index: 999;
  cursor: pointer;
}

.overlay-arrows_left {
  display: flex;
  background-color: #6d6d6d;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  width: 50px;
  height: 50px;
  left: 0;
  z-index: 999;
}

.overlay-arrows_left svg {
  width: 50px;
  height: 50px;
}

.overlay-arrows_right svg {
  width: 50px;
  height: 50px;
}

.overlay-arrows_right {
  display: flex;
  background-color: #6d6d6d;
  justify-content: space-between;
  position: absolute;
  right: 0;
  width: 50px;
  height: 50px;
  top: 50%;
  z-index: 999;
}

@media screen and (min-width: 400px) {
  .overlay img {
    height: 40%;
    object-fit: cover;
  }
} */