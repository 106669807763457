/* GoogleReview.css */
/* .google-review{ */
/* padding: 10px; */
/* } */

.google-review-img {
  width: 100%;
  transition: transform 0.3s ease-in-out;
}

.google-review-img:hover {
  transform: scale(1.1);
}