/* RoomCards.css */
.roomspage .room-card {
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  height: 300px;
}

.roomspage .room-image {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  height: 100%;
}

.roomspage .card-title {
  font-family: Montserrat, Verdana, Helvetica, Arial, sans-serif;
}

.roomspage .text-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem;
  color: white;
  background: rgba(0, 0, 0, 0.1);
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.roomspage .room-card:hover .room-image {
  opacity: 1;
}

.roomspage .room-card:hover .text-overlay {
  opacity: 0.9;
  background: rgba(0, 0, 0, 0.4);
}

.roomspage .room-card:hover .details-button {
  opacity: 1;
  transform: translateY(0);
}

.roomspage .details-button {
  opacity: 0;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  transform: translateY(20px);
  margin-top: 1rem;
  background-color: rgb(238, 247, 139);
  border: none !important;
}

.roomspage .details-button:hover {
  background-color: rgb(192, 202, 90);
}