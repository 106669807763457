body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.wrapper {
    max-width: 1350px;
    padding: 0px 60px;
    margin: 50px auto;
    display: flex;
    flex-wrap: wrap;
    gap: 2em;
}

.wrapper img {
    cursor: pointer;
}

/* modal */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: black;
    display: flex;
    align-items: center;
    z-index: 20;
}

.overlay img {
    display: block;
    max-width: 60%;
    max-height: 80%;
    margin: 60px auto;
    box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
}

.overlay>span {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 30px;
    color: #ffffff;
    z-index: 999;
    cursor: pointer;
}

.left {
    display: flex;
    /* background-color: #6d6d6d; */
    justify-content: space-between;
    position: absolute;
    top: 50%;
    width: 50px;
    height: 50px;
    left: 0;
    z-index: 999;
}

.overlay-arrows_left svg {
    width: 50px;
    height: 50px;
}

.overlay-arrows_right svg {
    width: 50px;
    height: 50px;
}

.overlay-arrows_right {
    display: flex;
    /* background-color: #6d6d6d; */
    justify-content: space-between;
    position: absolute;
    right: 0;
    width: 50px;
    height: 50px;
    top: 50%;
    z-index: 999;
}

/* @media screen and (max-width: 376px) {
    .overlay img {
        height: 60%;
        object-fit: cover;
    }
} */