.bgi .page-heading {
    height: 150px;
    /* Set the desired height for the background image */
    display: flex;
    align-items: center;
    justify-content: center;
    background: url('./Kiskinda_Heritage_Resort_bg1.jpg') center center;
    /* /Images/bg1.jpg */
    font-family: PlayfairDisplay, Georgia, Times New Roman, serif;
    text-align: center;
    background-position: top;
    background-size: cover;
    position: relative;
}

.bgi .page-heading::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.8);
    height: 100%;
    width: 100%;
}

.bgi .page-heading h1 {
    color: #fff;
    font-size: 2.5rem;
    /* Set the font size for the heading */
    margin: 0;
    position: relative;
}