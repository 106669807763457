.custom-container {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  background: rgb(255, 255, 255);
  /* padding: 10px ; */

}

.roww {
  justify-content: space-around;
}

.columnn {
  margin: 0px;
}

.card-text {
  font-family: Montserrat;
}

.cardcolor {
  background: #f4fdff !important;
  border-radius: 5vh;
  padding-left: 2vh;
  box-shadow: 0 2px 4px #a9d1dc;
}

.cardcolor .card-title {
  font-family: PlayfairDisplay, Georgia, Times New Roman, serif;
  font-weight: 500;
}

.discount-btn {
  font-family: 'Montserrat', sans-serif !important;
  background-color: rgb(240, 100, 100) !important;
  color: #fff;
  border: none !important;
}

.discount-btn:hover {
  background-color: rgb(203, 51, 51) !important;
}

.fixed-heading {
  position: sticky;
  margin-bottom: 40px;
  top: 0;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 4px 0px 0px rgba(255, 255, 255, 1), -4px 0px 0px rgba(255, 255, 255, 1);
  z-index: 1;
  /* Ensure the heading is on top of the scrolling content */
}

.containerparent {
  padding: 0px;
  /* background-color: rgba(225, 222, 222, 1); */
}

.carousel-scroll {
  scroll-behavior: smooth;
  animation: scrollAnimation 15s linear infinite;
  /* Adjust the duration as needed */
}

.custom-scroll {
  max-height: 500px;
  overflow-y: auto;
  scrollbar-width: none;
}

.custom-scroll::-webkit-scrollbar {
  width: 12px;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.custom-scroll::-webkit-scrollbar-track {
  background-color: transparent;
}

.carousel-scroll:hover {
  animation-play-state: paused;
}

@keyframes scrollAnimation {
  0% {
    transform: translateY(100px);
  }

  100% {
    transform: translateY(calc(-100% + 100px));
    /* Adjust the height based on your content  */
  }
}

@media screen and (max-width:768px) {
  .roww {
    width: 90%;
  }
}