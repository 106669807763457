.thankyou-head {
  font-size: 40px;
  font-weight: 700;
  color: #0c0c0c;
}
.thakyou-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  justify-content: center;
  margin-top: 70px;
}
.thakyou-containerss {
  width: 50%;
}
.thankyou-secondcont {
  background-color: #ffffae;
  display: flex;
  padding: 50px;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-top: 90px; */
}
.thankyou-headss {
  font-size: 18px;
  font-weight: 500;
}
.thank-you-cont {
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: space-between;
}
@media (max-width: 1025px) {
  .thankyou-secondcont {
    margin-top: 30px;
  }
  .thakyou-container {
    margin-top: 60px;
  }
}
@media (max-width: 1025px) {
  .thankyou-head {
    font-size: 40px;
    font-weight: 700;
    color: #0c0c0c;
  }
  .thakyou-container {
    margin-top: 60px;
  }
}
@media (max-width: 595px) {
  .thankyou-head {
    font-size: 22px;
    font-weight: 700;
    color: #0c0c0c;
  }
  .thakyou-container {
    margin-top: 100px;
  }
  .thankyou-headss {
    font-size: 11px;
    font-weight: 500;
  }
}
