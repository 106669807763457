/* TripAdvisorReview.css */
.tripadvisor-review {
  padding: 10px;
}

.tripadvisor-logo {
  width: 100%;
}

.widWRMHName {
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 10px;
}

.widWRMReviewButton {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 8px 16px;
  font-size: 1rem;
  cursor: pointer;
}

.widWRMReviewButton:hover {
  background-color: #0056b3;
}

