/* .welcome{
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
} */

.welcome .container{
    font-family: Montserrat,Verdana,Helvetica,Arial,sans-serif !important;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.about h2{
    font-size: 24px;
    margin-bottom: 10px;
    margin-top: 0;
    margin-bottom: 15px;
    font-family: PlayfairDisplay,Georgia,Times New Roman,serif !important;
    font-weight: 500;
    line-height: 1.3;
    color: #222;
}
.about .text_center{
    text-align: center;
}

.about p{
    margin-bottom: 10px;
}
