 .popularr {
    position: relative;
  }
  .popularr::after {
    content: "";
    width: 100%;
    height: 40%;
    background: rgba(255, 255, 255, 0.85);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
 
  .popularr .contentt {
    margin-bottom: 50px;
  }

  .headingg {
    font-family: PlayfairDisplay,Georgia,Times New Roman,serif;
    /* font-weight: 500; */
    text-align: center;
    padding: 0 0 20px 0;
  }
  .headingg h1 {
    font-size: 30px;
    font-weight: 600;
  }
  
  .headingg .linee {
    width: 8%;
    margin: auto;
    margin-top: 0px;
    border-bottom: 2px dashed #55b7d2;
    position: relative;
  }
  
  .headingg .linee::after {
    content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: #55b7d2;
    border-radius: 50%;
    top: -6px;
    left: -20px;
  }
  
  .headingg .linee::before {
    content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: #55b7d2;
    border-radius: 50%;
    top: -6px;
    right: -20px;
  }

  /* .topp{
    margin-top: 100px;
    } */
