
/* image only */
.popular img {
  width: 100%;
  height: 20vh;
}

.popular{
  font-family:'Yeseva one';
}
/* whole container having both image and datails etc */
.popular .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width:fit-content;
  height: 100%;
  padding:1em;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin: 15px;
  background-color: white;
  box-shadow: 0 4px 8px #d2e7ed;
  background-color: #ffffff !important;
}

/* image container */
.popular .imgae {
  position: relative;
  /* to be edited */
}

/* h2 h3 h4 container */
.popular .details {
  text-align: center;
  position:relative;
  overflow: auto;
  scrollbar-width:none;
  /* to be edited */
}

/* heading or name */
.popular .cards h2 {
  font-family: PlayfairDisplay,Georgia,Times New Roman,serif;
  font-weight: 500;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.85);
  position: relative;
  margin-bottom: 30px;
}

/* line1 */
.popular .cards h2::after {
  position: absolute;
  content: "";
  bottom: -10px;
  left: 45%;
  height: 1px;
  width: 30px;
  background: black;
  /* to be edited */
}

/* line2 */
.popular .cards h2::before {
  position: absolute;
  content: "";
  bottom: -20px;
  left: 40%;
  height: 1px;
  width: 60px;
  background: black;
  /* to be edited */
}

/* details */
.popular .cards h3 {
  font-family: Montserrat,Verdana,Helvetica,Arial,sans-serif;
  position: relative;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 300;
}

/* timing */
.popular .cards h4 {
  font-family: PlayfairDisplay,Georgia,Times New Roman,serif;

  position: relative;
  font-size: 15px;
  color: rgba(56, 37, 37, 0.85);
  font-weight: 300;
}



/*----- control btn -------*/
.popular .control-btn .next,
.popular .control-btn .prev {
  top: 100%;
  background: none;
  visibility: hidden;
}
.popular .control-btn .prev {
  left: 45%;
}
.popular .control-btn .next {
  right: 45%;
}
.popular .control-btn .prev i,
.popular .control-btn .next i {
  background: #e2f8fe;
  visibility: visible;
  color: black;
  font-size: 15px;
  transition: 0.5s;
}
.popular .control-btn .prev i:hover,
.popular .control-btn .next i:hover {
  background: #55b7d2;
  color: white;
}

.control-btn .next {
  position: absolute;
  top: 40%;
  z-index: 10;
  right: 5%;
}

.control-btn .prev {
  position: absolute;
  top: 40%;
  z-index: 10;
  left: 5%;
}

.control-btn button {
  background-color: rgba(255, 255, 255, 0.3);
  width: 50px;
  height: 50px;
  line-height: 60px;
  text-align: center;
  border-radius: 50%;
  color: white;
  cursor: pointer;
}
.control-btn i {
  background-color: #042113;
  width: 45px;
  height: 45px;
  margin: auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
  font-size: 30px;
}


/*----- control btn -------*/
@media screen and (max-width: 768px) {
  .popular .control-btn .prev {
    left: 40%;
  }
  .popular .control-btn .next {
    right: 42%;
  }
}
