/* Hero.css */

@import url('https://fonts.googleapis.com/css2?family=Your+Attractive+Font&display=swap');

.hero-container {
  position: relative;
  width: 100%;
  height: 80%;
  overflow: hidden;
  margin-bottom: 20px;
  
}

.overlays {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 0;
}

.text-containers {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
  z-index: 1;
}

.headings {
  font-size: 3rem;
  font-family: PlayfairDisplay,Georgia,Times New Roman,serif;
  font-weight: 500;
  margin-bottom: 20px;
}

.subheadings {
  font-size: 1.5rem;
  font-family: Montserrat,Verdana,Helvetica,Arial,sans-serif;
  font-weight: 200;
  margin-bottom: 20px;
}

/* Media queries */
@media (max-width: 768px) and (min-width: 576px) {
  .hero-container {
    height: 80%;
  }
  
  /* .overlays {
    height: 80%;
  } */
  
  .headings {
    font-size: 2rem;
  }
  
  .subheadings {
    font-size: 1rem;
  }
}

@media (max-width: 575px) {
  .hero-container {
    height: 80%;
  }
  
  /* .overlays {
    height: 80%;
  } */
  
  .headings {
    font-size: 1rem;
  }
  
  .subheadings {
    font-size: 0.5rem;
  }
}
