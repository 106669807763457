.otherrooms h2{
    font-family: PlayfairDisplay,Georgia,Times New Roman,serif;
    font-size: 2rem;
}

.otherrooms .card{
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.other-room-image-container{
    height: 250px !important;
}

.otherrooms .card-title{
    font-family: PlayfairDisplay,Georgia,Times New Roman,serif;
    font-size: 1.5rem;
    font-weight: 500;
}