.footer {
  font-family: Montserrat, Verdana, Helvetica, Arial, sans-serif;
  background-color: #c2f2ff;
  color: black;
  padding: 20px 0;
}

.footer h2 {
  font-family: PlayfairDisplay, Georgia, Times New Roman, serif;
  color: black;
  margin-bottom: 20px;
  letter-spacing: 2px;
  font-size: 25px;
}

.footer p {
  font-weight: 300;
  letter-spacing: 1px;
}

.footer ul {
  list-style: none;
  padding: 0;
}

.footer .phoneno {
  font-weight: 600;
}

.footer ul li {
  margin-bottom: 10px;
}

.footer a {
  color: black;
  text-decoration: none;
  font-weight: 300;
  letter-spacing: 1px;
}

.footer a:hover {
  text-decoration: underline;
}

footer .fas {
  font-weight: 300;
}

footer .icons i {
  color: white;
  background: black;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border: 1px solid #e7e3e3;
  border-radius: 5px;
  margin: 3px;
  margin-bottom: 20px;
}

.legal {
  font-family: Montserrat, Verdana, Helvetica, Arial, sans-serif;
  text-align: center;
  font-size: 10px;
  padding: 5px;
  background-color: #000000;
  border-top: 1px solid rgb(234, 246, 100);
  color: rgb(255, 255, 255);
}

.legal a {
  color: white;
  text-decoration: none;
  font-weight: 700;
  letter-spacing: 1px;
}

.legal span {
  opacity: 0.5;
}

footer input {
  border: 1px solid rgb(63, 62, 62);
  border-radius: 5px;
  margin: 10px 0;
  width: 90%;
  padding: 20px;
  outline: none;
  border: none;
}

footer .btn {
  border-radius: 5px;
  text-align: center;
  width: 90%;
}

footer .btn {
  font-family: Montserrat, Verdana, Helvetica, Arial, sans-serif;
  padding: 10px 20px;
  background-color: rgba(232, 225, 90, 0.85);
  color: black;
  font-weight: 400;
  transition: 0.5s;
}

footer .btn:hover {
  background-color: #000;
  color: white;
}