/* Add your styling for testimonials here */
/* Adjust as needed */

.bg_light_blue {
  background: #ebf3f5 !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pt-30 {
  padding-top: 30px;
}


.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
}

div {
  display: block;
}

.custom_title {
  font-family: PlayfairDisplay, Georgia, Times New Roman, serif;
  font-weight: 600;
  font-size: 35px;
  line-height: 34px;
  /* text-transform: uppercase; */
}

.text-center {
  text-align: center;
}

.customIcon>img {
  margin: auto;
}

.container::before {
  display: table;
  content: "";
}

.testimonials_slider {
  position: relative;
}

.container .carousel-inner {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  width: 100%;
  /* Set the width to 300% to accommodate three cards at a time */
  transition: transform 0.5s ease;
  /* Add a smooth transition effect */
}

.container .carousel-item {
  flex: 0 0 100%;
  /* Set the flex property to ensure three cards are displayed */
}



.testimonials_slider {
  margin: 0 auto;
  max-width: 800px;
  /* Adjust the max-width as needed */
}

.testimonial-card {
  margin: 10px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px #d2e7ed;
  padding: 20px;
  text-align: center;
  width: 100%;
  /* Set the width to 100% for each testimonial card */
}

.testimonial-card h6 {
  font-family: PlayfairDisplay, Georgia, Times New Roman, serif;
  font-size: 1.3rem;
  font-weight: 500;
}

.testimonial-card .star-ratings {
  font-family: PlayfairDisplay, Georgia, Times New Roman, serif;
  font-weight: 600;
}

.testimonial-card p {
  font-family: Montserrat, Verdana, Helvetica, Arial, sans-serif;
  font-size: 0.9rem;
}

.testimonial-card span {
  font-family: Montserrat, Verdana, Helvetica, Arial, sans-serif;
  /* font-size: 0.8rem; */
}

/* Add other styles as needed */
/* .carousel-slide{
    align-items: start;
  } */

/* .container .carousel-control-prev{
    background-color: black;
    align-items: center;
  }
  .container .carousel-control-next{
    background-color: black;
    align-items: center;
  } */