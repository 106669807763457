.MainDash {
    /* padding-top: 1rem; */
    width: 77vw;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-left: 30px;
    /* height: 100%; */
    /* justify-content: space-evenly; */
}

@media screen and (max-width: 1200px) {
    .MainDash {
        justify-content: flex-start;
        margin-top: 2rem;
    }
}

@media screen and (max-width: 768px) {
    .MainDash {
        align-items: center;
        width: 18rem;
        margin-left: 10px;
    }
}