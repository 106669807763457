.back {
  font-family: Montserrat, Verdana, Helvetica, Arial, sans-serif;
  position: absolute;
  right: 8%;
  /* top: -70px; */
}

.single-page {
  font-family: Montserrat, Verdana, Helvetica, Arial, sans-serif;
  position: relative;
}


.single-page .otherdetails {
  margin: 20px;
}

/* SingleRoom.css */

.single-page .room-page-heading {
  font-family: PlayfairDisplay, Georgia, Times New Roman, serif;
  font-size: 2rem;
  margin-bottom: 20px;
}

.single-page .room-room-card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #ffffff !important;
  margin-bottom: 10px;
  /* Light background color */
}

.single-page .roomimage {
  /* align-items: center !important; */
  display: flex;
  /* align-items: center; */
}

.single-page .room-room-image {
  width: 100%;
  /* border-top-left-radius: 8px;
  border-top-right-radius: 8px; */
}

.single-page .room-room-details {
  padding: 0px 20px 0px 20px;
}

.single-page .room-room-subheading {
  font-family: PlayfairDisplay, Georgia, Times New Roman, serif;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 15px;
}

.single-page .room-room-description {
  margin-bottom: 15px;
}

.single-page .room-room-price {
  align-items: baseline;
  font-size: 1.2rem;
  /* position: absolute; */

}



.single-page .price-n-button {
  display: flex;
  justify-content: space-between !important;
  /* position: absolute; */
}



.single-page .primary-btn {
  padding: 10px 20px;
  background-color: rgb(240, 100, 100);
  color: white;
  font-weight: 400;
  transition: 0.5s;
  text-decoration: none;
}

.single-page .primary-btn:hover {
  background-color: rgb(203, 51, 51);
  color: white;
}

.single-page .price {
  font-size: 1.6rem;
  font-weight: 600;
  /* text-decoration:underline !important; */
}

.single-page .book-now-btn {
  border: none;
  border-radius: 0px;
}

/* .single-page h6{
  font-size: 14px;
} */