@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;500&family=Playfair+Display:wght@500;600;700&display=swap');


* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  
}

body {
  font-family: Montserrat,Verdana,Helvetica,Arial,sans-serif;
  /* background: url(/public/Images/bgtexture.svg); */
}

.popular {
  position: relative;
}
.popular::after {
  content: "";
  width: 100%;
  height: 40%;
  background: #ebf3f5;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.popular .card {
  margin: 10px;
}

.popular .content {
  font-family: Montserrat,Verdana,Helvetica,Arial,sans-serif;
  margin-bottom: 70px;
}

.heading {
  font-family: PlayfairDisplay,Georgia,Times New Roman,serif;
  text-align: center;
  padding: 20px 0 0px 0;
}
.heading h1 {
  font-size: 30px;
  font-weight: 600;
}

.heading .line {
  width: 8%;
  margin: auto;
  margin-top: 10px;
  border-bottom: 2px dashed #55b7d2;
  position: relative;
}

.heading .line::after {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  background-color: #55b7d2;
  border-radius: 50%;
  top: -6px;
  left: -20px;
}

.heading .line::before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  background-color: #55b7d2;
  border-radius: 50%;
  top: -6px;
  right: -20px;
}

.top {
  margin-top: 50px;
}

.full_container {
  max-width: 95%;
  margin: auto;
}


.add-reviews{
  margin-top: 15px;
  /* padding-top: 20px; */
  border: 40px;
  border-color: #1a6e85;
}
.review-card {
  height: 180px !important; /* Adjust the height as needed */
  /* margin-top: -10px; */
  padding: 10px;
  margin: 20px 0px;
  overflow: hidden;
  /* box-shadow: 0 4px 8px #d2e7ed; */
} 

.bg_light_blue2{
  background: #cbf0fb !important;
}