.navbar{
  font-weight: 500px;
  font-family: Montserrat,Verdana,Helvetica,Arial,sans-serif;
}

.custom-navbar {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2) !important;
  background-color: rgb(255, 255, 174);
}

.custom-navbar .logo img {
  height: 40px;
}

.custom-navbar .navbar-brand{
  margin-right: 0px !important;
}

.custom-navbar .nav-link {
  font-size: 15px;
  padding: 0px 7px !important;
  color: rgba(0, 0, 0) !important;
}

.custom-navbar .join-btn {
  color: rgb(87, 76, 76);
  border: none;
  text-decoration: none;
}

.custom-navbar .join-btn:hover {
  background-color: rgba(193, 99, 99, 0.85);
  text-decoration:none;
}

/* .custom-navbar .active{
  background-color: rgba(193, 99, 99, 0.85) ;
  border: none;
  text-decoration: none;
} */

.custom-navbar .dropdown-item:active{
  background-color: rgba(193, 99, 99, 0.85) !important;
}

.custom-navbar .user-icon {
  color: #fff;
}

/* Additional Styling for Small Screens (Max-width: 768px) */
@media (max-width: 768px) {
  .custom-navbar .navbar-toggler {
    order: -1; /* Move the menu icon to the left */
  }

  .custom-navbar .navbar-toggler-icon {
    order: 0; /* Move the menu icon to the left */
  }

  

  .custom-navbar .user-icon {
    order: 1; /* Move the user icon to the right */
    
  }

  .custom-navbar .navbar-nav.full-width {
    width: 30%; /* Set full width for Nav items when expanded */
    text-align: center; /* Center-align the Nav items */
    margin-top: 10px; /* Add top margin for better spacing */
  }

  .custom-navbar .navbar-nav.full-width .nav-link {
    display: block;
    width: 100%;
    text-align: center;
    margin: 5px 0; /* Add some vertical margin between Nav items */
    /* background-color: rgb(227, 167, 167); */
  }
  /* .navbar-collapse{
    justify-content:left;
  } */
}

.custom-navbar .nav-link {
  position: relative; /* Set position to relative for absolute positioning */
}

.custom-navbar .nav-link::before {
  content: ""; /* Add pseudo-element for hover effect */
  position: absolute; /* Set position to absolute */
  bottom: -2px; /* Position the pseudo-element at the bottom */
  left: 0; /* Align the pseudo-element to the left */
  width: 100%; /* Set width to 100% */
  height: 2px; /* Set height of the line */
  background-color: rgba(193, 99, 99, 0); /* Set initial background color */
  transition: background-color 0.3s ease; /* Add transition for smooth animation */
}

.custom-navbar .nav-link:hover::before {
  background-color: #55b7d2; /* Change background color on hover */
}

.navright{
  font-size: 17px !important;
  font-weight: 600 !important;
}
