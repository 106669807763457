.popular {
    position: relative;
  }
  .popular::after {
    content: "";
    width: 100%;
    height: 30%;
    background: #ebf3f5;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
 
  .popular .card {
    margin: 10px;
  }
 
  .popular .content {
    font-family: Montserrat,Verdana,Helvetica,Arial,sans-serif;
    margin-bottom: 50px;
  }
 
  .heading {
    font-family: PlayfairDisplay,Georgia,Times New Roman,serif;
    
    text-align: center;
    padding: 20px 0 0px 0;
  }
  .heading h1 {
    font-size: 30px;
    font-weight: 600;
  }
  
  .heading .line {
    width: 8%;
    margin: auto;
    margin-top: 10px;
    border-bottom: 2px dashed #55b7d2;
    position: relative;
  }
  
  .heading .line::after {
    content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: #55b7d2;
    border-radius: 50%;
    top: -6px;
    left: -20px;
  }
  
  .heading .line::before {
    content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: #55b7d2;
    border-radius: 50%;
    top: -6px;
    right: -20px;
  }

  .top {
    margin-top: 50px;
  }

  .full_container {
    max-width: 95%;
    margin: auto;
  }