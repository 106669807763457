/* NavbarBelow.css */
.navbar-below-container {
  font-family: Montserrat, Verdana, Helvetica, Arial, sans-serif;
  position: sticky;
  top: 0;
  background-color: rgba(255, 255, 255, 1);
  z-index: 100;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 0 10px;
  padding: 5px;
}

/* Adjust the styles for the Dropdown directly */
.navbar-below-container .dropdown-toggle {
  height: 45px;
  border-radius: 0;
  box-shadow: none;

}

.hotel-dropdown {
  background-color: rgba(255, 255, 255, 1) !important;
  color: black !important;
  border: none !important;
  margin-top: 10px;
}

.navbar-below-container .dropdown-item:active {
  background-color: #55b7d2 !important;
}

/* .navbar-below-container .emptycol{
  padding: 0px !important;
  margin: 0px;
} */

/* Adjust the styles for the DateRangePicker directly */
.navbar-below-container .col-md-6 {
  display: flex;
  /* position: relative; */
  justify-content: center;
}

.navbar-below-container .col-md-12 {
  display: flex;
  /* position: relative; */
  justify-content: center;
}

.navbar-below-container .DateRangePicker {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  align-items: center;
  /* flex-basis: 100%; */
  width: auto;
  /* position: absolute; */

  /* width: 100%; */
}

/* .navbar-below-container .DateRangePickerInput {
  width:100%;
} */

.navbar-below-container .DateInput_1 {
  width: auto;
}

.navbar-below-container .DateInput_input_1 {
  width: auto;
  text-align: center;
}

.navbar-below-container .DateInput_input {
  width: auto;
}

.navbar-below-container .dropdown {
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  text-align: center;
}

/* Adjust the styles for the button directly */
.navbar-below-container .book-now-button {
  font-family: 'Montserrat', sans-serif !important;
  background-color: rgb(240, 100, 100);
  color: #fff;
  width: 100%;
  padding: 12px 20px;
  border-radius: 0px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Adjust the styles for the button hover directly */
.navbar-below-container .book-now-button:hover {
  background-color: rgb(203, 51, 51);
}

.navbar-below-container .book-now-button:active {
  background-color: rgb(240, 100, 100) !important;
}

.navbar-below-container .calendar-icon {
  font-size: 24px;
  /* Adjust size as needed */
  margin: 10px;
  /* Adjust margin as needed */
  cursor: pointer;
}

@media (max-width: 1024px) {
  .navbar-below-container .DateInput_input_1 {
    max-width: 120px;
    width: auto;
    text-align: center;
  }

  .DateRangePicker_picker_1 {
    max-width: 200px !important;
  }

  .DayPicker__horizontal {
    max-width: 200px !important;
    display: flex;
    flex-direction: column;
  }
}