.contact_us_content {
  font-family: Montserrat, Verdana, Helvetica, Arial, sans-serif;
  background-color: #f8f9fa;
  /* Add a background color to the entire component */
}

.contact_us_content .card-title {
  font-family: PlayfairDisplay, Georgia, Times New Roman, serif;
  font-weight: 600;
  font-size: 1.5rem;
}

.contact .contact-us {
  font-family: Montserrat, Verdana, Helvetica, Arial, sans-serif;
}

.contact .contact-card {
  background-color: #ffffff;
  /* White background for the form */
  border: 1px solid #dee2e6;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact .info-card {
  background-color: #ffffff;
  /* White background for the info cards */
  border: 1px solid #dee2e6;
  border-radius: 10px;
  padding: 20px;
  height: calc(33.33% - 20px);
  /* One-third of the total height minus some padding */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact .contact-card .btn-primary {
  font-family: 'Montserrat', sans-serif !important;
  background-color: rgba(211, 204, 74, 0.85);
  color: #fff;
  width: 100%;
  padding: 12px 50px;
  border-radius: 0px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.contact .contact-card .btn-primary:hover {
  background-color: rgba(236, 230, 106, 0.85);
}

.contact .info-card .card-title {
  color: #55b7d2;
}

.contact .info-card .card-text {
  color: #495057;
}

/* Additional styling for the form */
.contact .contact-card form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.contact .contact-card form label {
  color: #495057;
}

.contact .contact-card form input,
.contact .contact-card form textarea {
  border: 1px solid #ced4da;
  border-radius: 5px;
  margin-bottom: 10px;
}

.contact .contact-card form input:focus,
.contact-card form textarea:focus {
  border-color: #55b7d2;
  box-shadow: 0 0 0 0.2rem #55b7d21f;
}

/* Adjusting vertical alignment of the cards */
.contact .info-card:not(:last-child) {
  margin-bottom: 20px;
  /* Add some margin to separate the info cards */
}