.privacy-policy-container {
    margin-top: 20px;
    margin-bottom: 20px;
}

.privacy-policy-card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-top: 20px;
}

.privacy-policy-heading {
    font-family: PlayfairDisplay,Georgia,Times New Roman,serif !important;
    font-size: 34px;
    font-weight: bold;
    margin-bottom: 20px;
}

.privacy-policy-card h5{
    font-weight: 600;
}
.privacy-policy-intro,
.privacy-policy-section {
    font-size: 16px;
    margin-bottom: 20px;
}

/* Add more styles as needed */